.sidebar {
    // border-right: 1px solid #ebebeb;
}

.ant-menu-title-content:hover {
  color: black !important;
}
/* styles.scss */
.custom-menu {
  font-size: 16px;
  background-color: #09090B;
  border: none !important;
  color: white !important;
  font-family: 'Inter' !important;

  .ant-menu-item {
    color: white !important;
    &:hover {
      color: black !important;
      background-color: #FFC22A !important;
    }
  }

  .ant-menu-item-selected {
    background-color: #FFC22A;
  }

  .ant-menu-submenu-title {

    // &:hover {
    //   color: yellow !important;
    // }
  }

  .ant-menu-item-divider {
    border-color: #FFC22A !important;
  }

  span, i, div {
    color: white !important;
  }
}
  

.custom-menu-config {
  font-size: 16px;
  font-weight: 400;
  color: white;
  padding: 15px 0px;
  border-top: 1px solid #28282a;

  ul {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  li {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0px 4px;
    border-radius: 5px;
    padding-left: 25px;
    height: 40px;
  }

  li:hover {
    cursor: pointer;
    color: black;
    background-color: #FFC22A;
    transition: background-color 0.5s ease;
  }
}

.sidebar-container {
  transition: transform 0.3s ease-in-out;

  .menu-content {
    overflow-y: auto;

    /* Estilizando a barra de rolagem */
    &::-webkit-scrollbar {
      width: 8px; /* Largura da barra de rolagem */
    }

    &::-webkit-scrollbar-track {
      background: #FFC22A; /* Cor do fundo da barra de rolagem */
    }

    &::-webkit-scrollbar-thumb {
      background: #FFC22A; /* Cor da barra de rolagem */
      border-radius: 4px; /* Bordas arredondadas */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555; /* Cor da barra de rolagem ao passar o mouse */
    }
  }
}

@media (min-width: 768px) {
  .sidebar-container {
    transform: translateX(0);
    position: relative;
  }
}

.menu-icon {
  position: relative;
}

.hamburger-icon {
}

.pull {}