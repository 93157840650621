@import './variables';

/* Estilos Globais */
body {
    font-size: 17px;
    width: 100%;
    overflow-x: hidden;
    color: #172b4d;
}

.limited {
    max-width: 1250px;
}

@media (max-width: 768px) {
    .limited {
        max-width: 380px;
    }
}

.isDesktop {
    display: flex;
}

.isMobile {
    display: none;
}

@media (max-width: 768px) {
    .isMobile {
        display: block;
    }

    .isDesktop {
        display: none;
    }
}

.growth {
    transition: transform 0.3s ease, background-color 0.3s ease;
    &:hover {
        transform: scale(1.04);
    }
}

input {
    font-size: 14px;
}