.login-form {
  .label-form {
    margin-bottom: 5px !important;
  }
  #login {
    padding: 12px !important;
    font-size: 18px;
  }

  #password {
    padding: 12px !important;
    font-size: 18px;
  }
}
